/**
 * @author Peter
 * @email [hoangvanlam9988@gmail.com]
 * @create date 2022-01-26 11:42:50
 * @modify date 2022-01-26 11:42:50
 * @desc [description]
 */

/** Untils */
import { UrlInternal } from "common/constants/endpoints";
import GlobalStyles from "common/style/GlobalStyle";
import Normalize from "common/style/Normalize";
import defaultTheme from "common/style/themes/default";
import React, { lazy } from "react";
import {
  BrowserRouter as Router,
  // Route,
  Switch,
} from "react-router-dom";
/** Router Global */
import { PrivateRoute, PublicRoute } from "router";
import { ThemeProvider } from "styled-components";
import ScrollToTop from "utils/ScrollToTop";

const Home = lazy(() => import("pages/Home"));
const Introduce = lazy(() => import("pages/Introduce"));
const Describe = lazy(() => import("pages/Describe"));
const FormParticipation = lazy(() => import("pages/FormParticipation"));
const RegisterForm = lazy(() => import("pages/RegisterForm"));
const FormAccept = lazy(() => import("pages/FormAccept"));
const RegisterFormRead = lazy(() => import("pages/RegisterFormRead"));

const App: React.FC = () => {
  return (
    <ThemeProvider theme={defaultTheme}>
      <Normalize />
      <GlobalStyles />
      <Router>
        <ScrollToTop />
        <Switch>
          <PublicRoute exact path={UrlInternal.HOME} component={Home} />
          <PublicRoute
            exact
            path={UrlInternal.INTRODUCE}
            component={Introduce}
          />
          <PublicRoute exact path={UrlInternal.DESCRIBE} component={Describe} />
          <PublicRoute
            exact
            path={UrlInternal.FORM_PARTICIPATION}
            component={FormParticipation}
          />
          <PublicRoute
            exact
            path={UrlInternal.REGISTER_FORM}
            component={RegisterForm}
          />
          <PublicRoute
            exact
            path={UrlInternal.REGISTER_ACCEPT}
            component={FormAccept}
          />
          <PublicRoute
            exact
            path={UrlInternal.REGISTER_ACCEPT_READ}
            component={RegisterFormRead}
          />
        </Switch>
      </Router>
    </ThemeProvider>
  );
};

export default App;
