/**
 * @author Peter
 * @email [hoangvanlam9988@gmail.com]
 * @create date 2022-01-21 15:03:15
 * @modify date 2022-01-21 15:03:15
 * @desc [description]
 */

import { combineReducers } from "redux";
import filterLeftMenuReducer from "./filterLeftMenuReducer";

export default combineReducers({
  filterLeftMenu: filterLeftMenuReducer,
});
